body {
  overflow: hidden;
  background-color: #15001f;
}

.bissexto-app {
  position: fixed;
  left: 6.95vw;
  right: 6.35vw;
  top: 9.59vh;
  bottom: 9.05vh;

  background: linear-gradient(
      180deg,
      rgba(255, 0, 0, 2e-5) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    #d9c5e9;
}

.main-title {
  position: fixed;
  left: -38vw;
  top: 6vh;
  transform: translate(50vw, 0vh);
  font-family: Abhaya Libre;
  font-style: normal;
  font-weight: bold;
  font-size: calc(4vw + 2vmin);
  line-height: 109%;

  text-align: center;
  letter-spacing: calc(2vw + 0vmin);
  text-transform: uppercase;

  color: #f4f4f4;
}

.wedding-info {
  position: fixed;
  left: 9vw;
  top: 25vh;

  font-family: Abhaya Libre;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0vw + 2vmin);
  line-height: 102%;

  letter-spacing: 1vw;
  text-transform: uppercase;

  color: #f4f4f4;
}

.tips-title {
  color: #f4f4f4;
  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 111%;
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 111%;
  right: -10vw;
  position: relative;
}

.wedding-place {
  position: fixed;
  left: 17vw;
  top: 30vh;

  font-family: Abhaya Libre;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0vw + 2vmin);
  line-height: 130%;

  letter-spacing: 1vw;
  text-transform: uppercase;

  color: #f4f4f4;
}

.arrow {
  position: fixed;
  width: 5vw;
  left: 9vw;
}

.home-info {
  position: absolute;
  width: 50vw;
  top: 30vh;
  left: 35vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: calc(1vw + 2vmin);
  line-height: 4vh;

  color: #000000;
}

.invite-info {
  position: absolute;
  width: 50vw;
  top: 25vh;
  left: 40vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: calc(1vw + 2vmin);
  line-height: 4vh;

  color: #000000;
}

.tips-info {
  position: absolute;
  width: 50vw;
  top: 20vh;
  left: 40vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: calc(1vw + 2vmin);
  line-height: 4vh;

  color: #000000;
}

.monetary-tips-info {
  position: absolute;
  width: 45vw;
  top: 20vh;
  left: 40vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  line-height: 4vh;

  color: #000000;
}

.tips-menu-1 {
  position: absolute;
  width: 50%;
  height: 20%;
  top: 105%;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 70%;

  text-transform: uppercase;
  text-align: center;

  background: #e9dabc;
}

.tips-menu-2 {
  position: absolute;
  width: 50%;
  height: 20%;
  top: 130%;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 70%;

  text-transform: uppercase;
  text-align: center;

  background: #e9dabc;
}

.tips-menu-3 {
  position: absolute;
  width: 50%;
  height: 20%;
  top: 155%;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 70%;

  text-transform: uppercase;
  text-align: center;

  background: #e9dabc;
}

@media screen and (max-width: 600px) {
  .hidden-mobile {
    visibility: hidden;
    display: none;
  }
}

.circle {
  position: relative;
  left: -20vw;
  width: 71vw;
  height: 71vw;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.red {
  background: #eb5757;
  mix-blend-mode: normal;
  opacity: 0.1;
}

.left-fabio {
  /*min-width: 25%;*/
  position: absolute;
  top: 10vh;
  left: 15vw;
  width: 10vw;
  /*min-width: 80px;*/
}

.left-natalia {
  position: absolute;
  top: 10vh;
  left: 27vw;
  width: 10vw;
}

.right-us {
  position: absolute;
  left: 69.2%;
  right: 0%;
  top: 59.53%;
  bottom: 0%;

  mix-blend-mode: multiply;
  opacity: 0.5;
}

.center-text {
  position: fixed;
  text-align: center;
  left: 40vw;
  bottom: 12vh;

  font-family: Aladin;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vw;
  line-height: 125%;

  letter-spacing: 0.05em;

  color: #ffffff;
}

.menu {
  position: fixed;
  width: 30vw;
  height: 20vh;
  top: 40vh;

  background: rgba(227, 139, 162, 0.58);
  mix-blend-mode: color-dodge;
  transform: rotate(-0.1deg);
}

.menu-items {
  position: absolute;
  list-style-type: none;
  left: 3vw;
  top: 3vh;
  width: 50vw;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin-top: 1vh;
  font-family: Artifika;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vw;
  color: #000000;

  letter-spacing: 0.5vw;
  text-transform: uppercase;
}

.left-component {
  position: fixed;
  left: -7vw;
  bottom: -10vh;
  width: 50vw;
  height: 50vh;
}

.tips-center-text {
  position: fixed;
  text-align: center;
  left: 40vw;
  bottom: 12vh;

  font-family: Aladin;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vw;
  line-height: 125%;

  letter-spacing: 0.05em;

  color: #08437A;
}

.help-info {
  position: absolute;
  width: 45vw;
  top: 20vh;
  left: 40vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: calc(1vw + 2vmin);
  line-height: 4vh;

  color: #000000;
}

.beauty-salon-info {
  position: absolute;
  width: 45vw;
  top: 20vh;
  left: 40vw;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3vw;
  line-height: 4vh;

  color: #000000;
}

.underline-text {
  text-decoration-line: underline;
}

.uppercase-text {
  text-transform: uppercase;
}

.dash-list-style {
  list-style: none;
}

.dash-list-style li:before {
  content: '-';  
  padding-right: 10px;
}

.dash-list-style li {
  text-indent: -30px;
}